import React, { useState } from 'react';
import { FaWhatsapp, FaInstagram, FaEnvelope } from 'react-icons/fa'; 
import './Formulario.css';
import Logo from '../assets/Logo_png.png';
import HeaderWithSquare from "../components/HeaderWithSquare";
import Cadastur from '../assets/cadastur-logo.png';

function Formulario() {
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    telefone: '',
    destino: '',
    mensagem: '',
  });

  const [submitted, setSubmitted] = useState(false); // Estado para indicar que o formulário foi enviado

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // URL do seu formulário no Formspree
    const formspreeUrl = 'https://formspree.io/f/mnnakdve'; // Substitua pelo seu Form ID

    fetch(formspreeUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then((response) => {
      if (response.ok) {
        setSubmitted(true);
        setFormData({ nome: '', email: '', telefone: '', destino: '', mensagem: '' });
        alert('Formulário enviado com sucesso!');
      } else {
        alert('Ocorreu um erro ao enviar o formulário. Tente novamente.');
      }
    })
    .catch((error) => {
      console.error('Erro ao enviar o formulário:', error);
      alert('Ocorreu um erro. Por favor, tente novamente mais tarde.');
    });
  };

  return (
    <section className="formulario">
      <div className="formulario-container">
        {/* Coluna do Formulário */}
        <div className="formulario-left">
          <HeaderWithSquare title="Entre em contato" />
          <h3>Quer planejar a viagem dos sonhos? Entre em contato e deixe que cuidemos de todos os detalhes.</h3>
          {submitted ? (
            <p>Obrigado por sua mensagem! Entraremos em contato em breve.</p>
          ) : (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="nome"
                placeholder="Seu Nome"
                value={formData.nome}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Seu Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input
                type="tel"
                name="telefone"
                placeholder="Seu Telefone"
                value={formData.telefone}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="destino"
                placeholder="Destino de Interesse"
                value={formData.destino}
                onChange={handleChange}
              />
              <textarea
                name="mensagem"
                placeholder="Deixe mais informações sobre sua viagem, incluindo as datas que deseja viajar..."
                value={formData.mensagem}
                onChange={handleChange}
                required
              ></textarea>
              <button type="submit"><FaEnvelope /> Enviar Mensagem</button>
            </form>
          )}
        </div>

        {/* Coluna com Informações de Contato */}
        <div className="formulario-right">
          <img src={Logo} alt="Logo da Agência" className="logo-agencia" />
          <p>CNPJ: 57.100.405/0001-19</p>
          <p>Telefone: +55 48 99839-9894</p>
          <p>Email: contato@desconecteviagens.com.br</p>
          <div className="social-icons">
            <a href="https://wa.me/5548988703921" target="_blank" rel="noopener noreferrer" className="whatsapp-icon">
              <FaWhatsapp />
            </a>
            <a href="https://www.instagram.com/desconecte_viagens/" target="_blank" rel="noopener noreferrer" className="instagram-icon">
              <FaInstagram />
            </a>
          </div>
          <a className='cadastur' href="https://cadastur.turismo.gov.br/cadastur/#!/public/qrcode/57100405000119" target="_blank" rel="noopener noreferrer">
            <img src={Cadastur} alt="Logo Cadastur" className="logo-agencia" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Formulario;
